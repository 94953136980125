import punycode from "punycode";
import React, { useRef } from "react";
import useNoWrapFontStyle from "../../../commons/useNoWrapFontStyle";
import { Settings } from "../../../config";
import { useIntl } from "react-intl";

import classes from "./DomainDisplay.module.scss";
import classNames from "classnames";

const isPunyCode = (domain) => /^xn--/.test(domain);

type DomainDisplayProps = {
  domainName: string;
  className?: string;
};

export const DomainDisplay = ({
  domainName,
  className,
}: DomainDisplayProps) => {
  const intl = useIntl();
  const domainContainerRef = useRef(null);
  const fontStyle = useNoWrapFontStyle(
    domainName,
    domainContainerRef,
    Settings.NORMAL_DOMAIN_LENGTH_LIMIT
  );

  return (
    <div
      ref={ domainContainerRef }
      className={ classNames(classes.domainDisplayLayout, className) }
    >
      <div style={ fontStyle }>{punycode.toUnicode(domainName)}</div>
      {isPunyCode(domainName) && (
        <span className={ classes.intlMessage }>
          {intl.formatMessage(
            { id: "thisDomainIsPunyCode" },
            {
              domain: domainName,
              link: (str) => (
                <a
                  href="https://www.icann.org/resources/pages/idn-2012-02-25-en"
                  target="_blank"
                  className={ classes.internationalizedLink }
                  rel="noreferrer"
                >
                  {str}
                </a>
              ),
            }
          )}
        </span>
      )}
    </div>
  );
};
