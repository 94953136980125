import React from "react";
import { getVerizonAdsUri } from "./verizonFeed";
import { toTitleCase } from "../commons/HelperFunctions";
import { useLanderConfig } from "../commons/ConfigContext";
import PropTypes from "prop-types";
import SearchIcon from "@ux/icon/search";
import ChevronRightIcon from "@ux/icon/chevron-right";
import "@ux/icon/search/index.css";
import "@ux/icon/chevron-right/index.css";

export const YahooRS = ({ xmlResult }) => {
  const config = useLanderConfig();
  const rootDomain = config.landerConfig.domain.rootDomain;
  const resultSet =
    xmlResult?.getElementById("relatedTerms")?.getElementsByTagName("Search") ??
    [];
  const verizonFeed = Array.from(resultSet);

  return (
    <div className="yahooRS">
      <div className="relatedTopicsHeader">
        <span>Related Search Topics</span>
      </div>
      <ul>
        {
          // create related link items
          verizonFeed &&
            verizonFeed.map((item: HTMLElement, itemIndex) => (
              <li key={ `verizon-feed-item-${itemIndex}` }>
                <div className="iconContainer">
                  <SearchIcon
                    style={{ color: "black" }}
                    height="32"
                    width="32"
                  />
                </div>
                <a
                  href={ getVerizonAdsUri(
                    item.getAttribute("queryParams"),
                    rootDomain
                  ) }
                >
                  {toTitleCase(item.innerHTML)}
                </a>
                <div style={{ minWidth: "32px" }}>
                  <ChevronRightIcon
                    style={{ color: "black" }}
                    height="32"
                    width="32"
                  />
                </div>
              </li>
            ))
        }
      </ul>
    </div>
  );
};

YahooRS.propTypes = {
  xmlResult: PropTypes.object,
};
