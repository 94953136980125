import React from "react";
import { AdSense, getAdSensePageType } from "./utils/adSenseUtils";
import { ValidTemplates } from "../commons/constants/ValidTemplates";
import SimpleLayout from "./layout/SimpleLayout";
import Logger from "../commons/Logger";
import { ErrorCode, EventType, QueryParam } from "../commons/Constants";
import { Settings } from "../config";
import { NoAdsPage } from "./NoAdsPage";
import postEvent, { createBaseLanderEvent } from "../commons/LanderEvent";
import HegLayout from "./layout/HegLayout";
import ParkWebLayout from "./layout/ParkWebLayout";
import ParkWebCardLayout from "./layout/ParkWebCardLayout";
import ParkWebWithSpins from "./layout/ParkWebWithSpins";
import { useLanderConfig } from "../commons/ConfigContext";
import { useTrustArcScript } from "../consentManager/useTrustArcScript";
import { getContent } from "./content/Content";
import { useExperiment } from "../experimentation/useExperiment";
import { experimentIds } from "../commons/experiment-helpers";

// eslint-disable-next-line complexity
export default function AdSensePage() {
  const { landerConfig, queryConfig } = useLanderConfig();
  const { inTreatmentCohort: parkWebCardDesignExp } = useExperiment(
    experimentIds.parkWebRedesignExperimentId
  );
  const { inTreatmentCohort: parkWebWithSpinsExp } = useExperiment(
    experimentIds.parkWebWithSpinsExperimentId
  );
  useTrustArcScript(landerConfig);

  // check if pubId and DRID are present. Proceed with CAF request regardless of DRID status
  const adSense = landerConfig.adSense;

  if (!adSense.drid || !adSense.pubId) {
    Logger.error("pubid or drid is missing");
    const errorCode = adSense.drid
      ? ErrorCode.ERROR_NO_PUBID
      : ErrorCode.ERROR_NO_DRID;
    sendInvalidAdsenseParamEvent(errorCode, landerConfig, queryConfig);

    // No ads but can render TDFS and other stuff
    return <NoAdsPage landerConfig={ landerConfig } queryConfig={ queryConfig } />;
  }

  if (!ValidTemplates.includes(landerConfig.lander.template)) {
    // This should not happen, we select a DEFAULT template while building landerConfig.
    Logger.warn("Unknown template " + landerConfig.lander.template);
  }

  // Get AdSense page (related link or sponsored listings)
  const page = getAdSensePageType(queryConfig);
  // Get localized content for a given template and locale
  const content = getContent(landerConfig.lander.template, landerConfig.system);

  let parkWebPage;
  if (parkWebCardDesignExp) {
    parkWebPage = <ParkWebCardLayout page={ page } content={ content } />;
  } else if (parkWebWithSpinsExp) {
    parkWebPage = <ParkWebWithSpins page={ page } content={ content } />;
  } else {
    parkWebPage = <ParkWebLayout page={ page } content={ content } />;
  }

  switch (landerConfig.lander.template) {
    case AdSense.template.SIMPLE_2C:
    case AdSense.template.SIMPLE_2C_LIGHT:
    case AdSense.template.LIGHT_BLUE:
    case AdSense.template.VERTICAL_LINES:
    case AdSense.template.SIMPLE_2C_EXPERIMENT:
    case AdSense.template.ARROW_1:
    case AdSense.template.ARROW_2:
    case AdSense.template.ARROW_3:
      return <SimpleLayout page={ page } content={ content } />;
    case AdSense.template.PARK_WEB:
      return parkWebPage;
    case AdSense.template.PARK_WEB_HEG:
      return <HegLayout page={ page } content={ content } />;
    default:
      Logger.warn("Unknown template " + landerConfig.lander.template);
      return <SimpleLayout page={ page } content={ content } />;
  }
}

// Error event if lander-param content is invalid (missing pubId, drid, etc.)
function sendInvalidAdsenseParamEvent(errorCode, landerConfig, queryConfig) {
  const event = createBaseLanderEvent(EventType.ERROR, landerConfig);
  event.errorCode = errorCode;
  event.sToken = queryConfig[QueryParam.S_TOKEN];
  postEvent(Settings.EVENT_PUBLISH_API, event);
}
