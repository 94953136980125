import React, { useState, useEffect } from "react";
import { getParkWebBannerText, isBannerEnabled } from "../../banner/Banner";
import TopBanner from "../../banner/TopBanner";
import { FooterParkWeb } from "../footer/FooterParkWeb";
import GetDomainButton from "../commonComponents/GetDomainButton";
import { TrustArcConsentDialog } from "../../consentManager/TrustArcComponents";
import { useConsentStatus } from "../../consentManager/useConsentStatus";
import { useLanderConfig } from "../../commons/ConfigContext";
import { AdContent } from "../AdContent";
import { LanderConfig } from "../../types/LanderConfig";
import classes from "./ParkWebWithSpins.module.scss";
import { SpinsModernTightened } from "@site/spins";
import getSpinLanguage from "@site/spins/locales";
import getPricingLanguage from "@site/price-block/locales";
import "@site/spins/dist/styles/styling.css";
import "@site/spins/dist/styles/styleWrapper.css";
import "@site/spins/lib/css/mediaQueries.css";
import { Settings } from "../../config";
import postEvent, { createBaseLanderEvent } from "../../commons/LanderEvent";
import { EventType } from "../../commons/Constants";

const MARKET = "en-US";
const APP_NAME = "parkweb";
const API_KEY = "parkweb_recommendations";
const REQUEST_API_TYPE = "SPINS_API";
const getLanguages = async () => {
  return Object.assign(
    {},
    await getSpinLanguage(MARKET),
    await getPricingLanguage(MARKET)
  );
};

type ParkWebWithSpinsProps = {
  page: string;
  content: { [key: string]: string };
};

type DomainInfoProps = {
  landerConfig: LanderConfig;
  content: { [key: string]: string };
};

function DomainInfo({ landerConfig, content }: DomainInfoProps) {
  const text = getParkWebBannerText(landerConfig, content);
  if (!text) return null;
  return <div className={ classes.domainInfo }>{text}</div>;
}

function Domain({ domain }: { domain: string }) {
  if (!domain) return null;
  return <div className={ classes.domain }>{domain}</div>;
}

export default function ParkWebWithSpins({
  page,
  content,
}: ParkWebWithSpinsProps) {
  const [languages, setLanguages] = useState({});
  const { landerConfig } = useLanderConfig();
  const consentStatus = useConsentStatus();
  const domain = landerConfig.domain?.rootDomainDisplayText;
  // eslint-disable-next-line no-process-env
  const env = process.env.NODE_ENV === "production" ? "prod" : "test";

  useEffect(() => {
    (async () => {
      setLanguages(await getLanguages());
    })();
  }, []);

  let domainContent = null;
  if (isBannerEnabled(landerConfig)) {
    domainContent = (
      <div className={ classes.contentLayout }>
        <div>
          <Domain domain={ domain } />
          <DomainInfo landerConfig={ landerConfig } content={ content } />
        </div>
        <div className={ classes.getDomain }>
          <GetDomainButton landerConfig={ landerConfig } />
        </div>
      </div>
    );
  }

  let adContent = null;
  if (consentStatus.showConsentDialog) {
    adContent = <TrustArcConsentDialog />;
  } else {
    adContent = (
      <div className={ classes.adContent }>
        <AdContent page={ page } />
      </div>
    );
  }

  const customOnClick = (data) => {
    sendSpinsClickEvent(landerConfig);
    window.location.href = `${Settings.ACTION_SERVICE}?domain=${data?.domain?.key}`;
  };

  return (
    <div className={ classes.parkWebLander }>
      <TopBanner flexColumnOnMobile={ true } />
      <div className={ classes.contentMain }>
        <div className={ classes.domainCard }>{domainContent}</div>
      </div>
      <div className={ classes.contentSuggestions }>
        <SpinsModernTightened
          requestApiType={ REQUEST_API_TYPE }
          appName={ APP_NAME }
          apiKey={ API_KEY }
          options={{
            apiKey: API_KEY,
            env: env,
            searchTerm: domain,
            privateLabelId: 1,
            market: MARKET,
            numberOfSpins: 5,
          }}
          ctaProps={{
            env: env,
            market: MARKET,
            apiKey: API_KEY,
            customOnClick,
          }}
          language={ languages }
        />
        {adContent}
      </div>
      <FooterParkWeb
        copyrightTextId="parkwebcopyrightText"
        parkwebDisclaimerText="parkwebDisclaimerText"
      />
    </div>
  );
}

export async function sendSpinsClickEvent(landerConfig) {
  const spinsClickEvent = createBaseLanderEvent(
    EventType.SPINS_CLICK,
    landerConfig
  );
  postEvent(Settings.EVENT_PUBLISH_API, spinsClickEvent);
}
