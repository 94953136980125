import React, { useState, useEffect, createContext, useContext } from "react";
import { getAdSensePageType } from "./utils/adSenseUtils";
import { useLanderConfig } from "../commons/ConfigContext";
import loadAdsense from "./utils/loadAdsense";

export const AdsenseContext = createContext(null);

export const useAdsenseContext = () => {
  const ctx = useContext(AdsenseContext);

  if (ctx == null) {
    throw new Error("useAdsenseContext must be called within AdsenseProvider");
  }

  return ctx;
};

interface Props {
  children: React.ReactNode;
}

export const AdsenseProvider = ({ children }: Props) => {
  const [loadingAdsense, setLoadingAdsense] = useState(false); // Make sure we only load it once
  const [isAdsenseSuccess, setIsAdsenseSuccess] = useState();
  const { landerConfig, queryConfig, landerConfigDispatch } = useLanderConfig();
  const page = getAdSensePageType(queryConfig);

  useEffect(() => {
    if (!landerConfig.isForcedVerizon && !loadingAdsense) {
      setLoadingAdsense(true);
      loadAdsense(landerConfig, queryConfig, page, (success) => {
        setIsAdsenseSuccess(success);
      });
    }
  }, [
    landerConfig,
    page,
    queryConfig,
    setIsAdsenseSuccess,
    landerConfigDispatch,
    loadingAdsense,
    setLoadingAdsense,
  ]);

  return (
    <AdsenseContext.Provider value={{ hasAdsense: isAdsenseSuccess }}>
      {children}
    </AdsenseContext.Provider>
  );
};
