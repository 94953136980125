import React from "react";
import AdSensePage from "./adsense/AdSensePage";
import { LanderConfigProvider } from "./commons/ConfigContext";
import { DomainStatus, ErrorCode } from "./commons/Constants";
import {
  createLanderConfig,
  getApiUrl,
  parseLanderQueryParam,
} from "./commons/LanderConfig";
import { ErrorPage } from "./commons/LanderError";
import { useFetch } from "./commons/useFetch";
import TargetedLander from "./parkweb/TargetedLander";
import { I18nProvider } from "./i18n/I18nProvider";
import { AdsenseProvider } from "./adsense/AdsenseContext";

export const ParkingLander = () => {
  const queryConfig = parseLanderQueryParam(window.location.search);
  const { body, err } = useFetch(getApiUrl(queryConfig));

  if (err) {
    return (
      <ErrorPage
        landerConfig={{}}
        queryConfig={ queryConfig }
        errorCode={ ErrorCode.ERROR_FETCHING_DOMAIN_CONFIG }
        sendEvent={ true }
      />
    );
  }

  if (!body) {
    return null;
  }
  if (body.targeted) {
    return (
      <TargetedLander targeted={ body.targeted } queryConfig={ queryConfig } />
    );
  }

  const landerConfig = createLanderConfig(body, queryConfig);
  if (landerConfig.domain?.status?.internal !== DomainStatus.ACTIVE) {
    return (
      <ErrorPage
        landerConfig={ landerConfig }
        errorCode={ ErrorCode.ERROR_DOMAIN_STATUS_INVALID }
      />
    );
  }

  if (landerConfig.turnKeyReseller?.redirectUrl?.trim().length) {
    window.location.replace(landerConfig.turnKeyReseller.redirectUrl);
  }
  return (
    <I18nProvider isI18n={ landerConfig.lander?.i18n }>
      <LanderConfigProvider
        landerConfig={ landerConfig }
        queryConfig={ queryConfig }
      >
        <AdsenseProvider>
          <AdSensePage />
        </AdsenseProvider>
      </LanderConfigProvider>
    </I18nProvider>
  );
};
