import { useEffect } from "react";
import { Settings } from "../config";
import { injectTrustArcScript } from "./helper";
import { TA_PREFERENCES_ID } from "./TrustArcComponents";
import { LanderConfig } from "../types/LanderConfig";

// Initializes TrustArc CMP
export const useTrustArcScript = (landerConfig: LanderConfig) => {
  useEffect(() => {
    const privacyUrl =
      landerConfig?.turnKeyReseller?.privacyUrl ?? Settings.PRIVACY_POLICY_URL;
    injectTrustArcScript(TA_PREFERENCES_ID, privacyUrl);
  }, [landerConfig.turnKeyReseller]);

  return;
};
