// This is a country code to locale map
const languageMap = {
  da: "da-DK",
  "da-DK": "da-DK",
  de: "de-DE",
  "de-DE": "de-DE",
  el: "el-GR",
  "el-GR": "el-GR",
  "es-ES": "es-ES",
  "es-MX": "es-MX",
  en: "en-US",
  "en-US": "en-US",
  fi: "fi-FI",
  "fi-FI": "fi-FI",
  fr: "fr-FR",
  "fr-CA": "fr-CA",
  "fr-FR": "fr-FR",
  hi: "hi-IN",
  "hi-IN": "hi-IN",
  id: "id-ID",
  "id-ID": "id-ID",
  it: "it-IT",
  "it-IT": "it-IT",
  ja: "ja-JP",
  "ja-JP": "ja-JP",
  ko: "ko-KR",
  "ko-KR": "ko-KR",
  nb: "nb-NO",
  "nb-NO": "nb-NO",
  nl: "nl-NL",
  "nl-NL": "nl-NL",
  pl: "pl-PL",
  "pl-PL": "pl-PL",
  "pt-BR": "pt-BR",
  "pt-PT": "pt-PT",
  "qa-PS": "qa-PS",
  ru: "ru-RU",
  "ru-RU": "ru-RU",
  sv: "sv-SE",
  "sv-SE": "sv-SE",
  th: "th-TH",
  tr: "tr-TR",
  "th-TH": "th-TH",
  "tr-TR": "tr-TR",
  uk: "uk-UA",
  "uk-UA": "uk-UA",
  vi: "vi-VN",
  "vi-VN": "vi-VN",
  "zh-CN": "zh-CN",
  "zh-TW": "zh-TW",
};

export function getLocaleFromBrowser() {
  const lang = navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage;
  return lang || "en-US";
}

export function getLocalizedMessages(locale) {
  let messages;
  if (languageMap[locale]) {
    messages = require(`./locales/${languageMap[locale]}.json`);
  } else {
    messages = require(`./locales/en-US.json`);
  }
  return messages;
}
