import React from "react";
import PropTypes from "prop-types";
import { AdSense } from "../utils/adSenseUtils";

const ANGLED_OPACITY = "0.75";
const COLOR_A = "#82B9AD";
const COLOR_B = "#4387A0";
const COLOR_C = "#4F7170";
const COLOR_D = "#274554";

const UpperAngledRect = () => {
  return (
    <g opacity={ ANGLED_OPACITY }>
      <path d="M0.963379 78L134.963 128V50L0.963379 0V78Z" fill={ COLOR_A } />
      <path
        d="M0.963379 178L134.963 228V150L0.963379 100V178Z"
        fill={ COLOR_B }
      />
      <path
        d="M0.963867 276L134.964 326V248L0.963867 198V276Z"
        fill={ COLOR_C }
      />
      <path
        d="M0.963379 375L134.963 425V347L0.963379 297V375Z"
        fill={ COLOR_D }
      />
    </g>
  );
};

const LowerAngledRect = () => {
  return (
    <g opacity={ ANGLED_OPACITY }>
      <path d="M0.963379 100L134.963 50V128L0.963379 178V100Z" fill={ COLOR_A } />
      <path
        d="M0.963379 200L134.963 150V228L0.963379 278V200Z"
        fill={ COLOR_B }
      />
      <path
        d="M0.963867 298L134.964 248V326L0.963867 376V298Z"
        fill={ COLOR_C }
      />
      <path
        d="M0.963867 397L134.964 347V425L0.963867 475V397Z"
        fill={ COLOR_D }
      />
    </g>
  );
};

const arrowTemplateToUIConfig = (template) => {
  return {
    showUpperAngledRect: template !== AdSense.template.ARROW_2,
    showLowerAngledRect: template !== AdSense.template.ARROW_3,
  };
};

/**
 * svg generated in figma, imported, and edited. see PARKING-1572
 */
const ArrowSvg = ({ pointLeft, template }) => {
  const { showUpperAngledRect, showLowerAngledRect } =
    arrowTemplateToUIConfig(template);

  const arrowLength = "550px";
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div
        style={{
          zIndex: -1,
          display: "flex",
          justifyContent: pointLeft ? "flex-start" : "flex-end",
          position: "absolute",
          top: "-1.5rem",
          left: pointLeft ? "calc(100% * -1)" : "",
          right: !pointLeft ? "calc(100% * -1)" : "",
        }}
      >
        <svg
          viewBox="0 0 431 475"
          fill="none"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            transform: pointLeft ? "scaleX(-1)" : "",
            maxHeight: "600px",
            minHeight: "600px",
            maxWidth: arrowLength,
            minWidth: arrowLength,
          }}
        >
          <g opacity="0.29">
            {showUpperAngledRect && <UpperAngledRect />}
            {showLowerAngledRect && <LowerAngledRect />}
            <rect
              width="258"
              height="78"
              transform="matrix(-1 0 0 1 392.3 50)"
              fill={ COLOR_A }
            />
            <path
              d="M430.955 89.0082L391.963 50L391.963 128L430.955 89.0082Z"
              fill={ COLOR_A }
            />
            <rect
              width="233.195"
              height="78"
              transform="matrix(-1 0 0 1 368.195 150)"
              fill={ COLOR_B }
            />
            <path
              d="M407.001 189L368.032 150L368.031 228L407.001 189Z"
              fill={ COLOR_B }
            />

            <rect
              width="203"
              height="78"
              transform="matrix(-1 0 0 1 337.9 248)"
              fill={ COLOR_C }
            />

            <path
              d="M376.038 285.021L336.963 248L336.963 326L376.038 285.021Z"
              fill={ COLOR_C }
            />

            <rect
              width="176"
              height="78"
              transform="matrix(-1 0 0 1 310.1 347)"
              fill={ COLOR_D }
            />

            <path
              d="M349 386L309.963 347L309.963 425L349 386Z"
              fill={ COLOR_D }
            />
          </g>
        </svg>
      </div>
    </div>
  );
};
ArrowSvg.propTypes = {
  pointLeft: PropTypes.bool,
  template: PropTypes.string,
};

export const ArrowUIWrapper = ({ children, template }) => {
  if (!AdSense.template.ARROW_LIST.includes(template)) {
    return children;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "center",
        maxWidth: "100%",
      }}
    >
      <ArrowSvg template={ template } />
      <div>{children}</div>
      <ArrowSvg pointLeft template={ template } />
    </div>
  );
};
ArrowUIWrapper.propTypes = {
  children: PropTypes.node,
  template: PropTypes.string,
};
