import { useEffect, useState } from "react";
import { getIsConsentRequired } from "./helper";

// Returns true if we are waiting for user to input consent choice
export function useConsentStatus() {
  const isConsentRequired = getIsConsentRequired();
  const [isConsentLoading, setIsConsentLoading] = useState(isConsentRequired);

  useEffect(() => {
    if (!isConsentRequired) {
      return;
    }
    // poll for consent status
    const interval = setInterval(() => {
      if (window.__tcfapi !== void 0) {
        window.__tcfapi("getInAppTCData", 2, (inAppTCData, success) => {
          // If tcString is set, then the user has accepted or rejected consent
          if (success && inAppTCData.tcString !== "") {
            setIsConsentLoading(false);
            clearInterval(interval);
          }
        });
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [isConsentRequired]);

  return {
    // If true, ads should be hidden and <TrustArcConsentDialog/> should be displayed
    showConsentDialog: isConsentRequired && isConsentLoading,
  };
}
