import { objectToQueryStringExcludeNull } from "./HelperFunctions";
import { QueryParam } from "./Constants";

/**
 * Create a partner's callback event from Lander Event (VISIT, KEYWORD_CLICK, etc.)
 * Per spec, only few fields are required and sent.
 * @param landerEvent park page lander event
 * @param queryParam query parameters
 * @returns {json}
 */
export function createPartnerCallbackEvent(landerEvent, queryParam) {
  return {
    domain: landerEvent.domain,
    time: landerEvent.createdAt,
    type: landerEvent.eventType,
    keyword: landerEvent.keyword || null,
    uuid: queryParam[QueryParam.UUID] || null,
    s_token: queryParam[QueryParam.S_TOKEN] || null,
  };
}

/**
 * Returns clickTrackUrl to be called via. Google CAF after an Ad click.
 * @param landerParam lander-param API response
 * @param queryParam query parameters
 * @param landerEvent lander event object
 * @returns {string} a URL for Ad click callback
 */
export function getCallbackAdClickURL(landerParam, queryParam, landerEvent) {
  const event = createPartnerCallbackEvent(landerEvent, queryParam);
  return (
    landerParam.lander.eventCallbackUrl +
    "?" +
    objectToQueryStringExcludeNull(event)
  );
}
