import { useEffect } from "react";
import { LanderConfigAction, useLanderConfig } from "../commons/ConfigContext";

export function useExperiment(experimentId) {
  const { landerConfig, landerConfigDispatch } = useLanderConfig();
  const { experiment } = landerConfig;

  const inExperiment = experiment?.key === experimentId;
  const inTreatmentCohort =
    inExperiment && /treatment$/.test(experiment?.cohort);

  useEffect(() => {
    if (!inExperiment) return;

    landerConfigDispatch({
      type: LanderConfigAction.UPDATE_BANNER_AND_CHANNEL_BY_TREATMENT,
      payload: {
        inTreatmentCohort,
      },
    });
  }, [inExperiment, landerConfigDispatch, inTreatmentCohort]);

  return { inTreatmentCohort, inExperiment };
}
