import Logger from "../commons/Logger";

/**
 * Returns a PerformanceMetrics that holds two metrics: domComplete and loadEventEnd
 * @param perfEntry
 * @constructor
 */
function PerformanceMetrics(perfEntry) {
  this.perfEntry = perfEntry;
  this.domComplete = () => {
    return this.perfEntry
      ? this.perfEntry.domContentLoadedEventEnd -
          perfEntry.domContentLoadedEventStart
      : null; // performance.timing.domComplete = DOMContentLoaded
  };
  this.loadEventEnd = () => {
    return this.perfEntry
      ? this.perfEntry.loadEventEnd - this.perfEntry.loadEventStart
      : null; // performance.timing.loadEventEnd
  };
}

/**
 * Returns a PerformanceMetrics object constructed with navigation events
 * @returns {PerformanceMetrics}
 */
export function getPreformanceMetrics() {
  // The Performance interface provides access to performance-related information for the current page.
  // Use getEntriesByType() to just get the "navigation" events (a list of PerformanceEntry objects)
  let perfEntries;
  let p;
  // navigation PerformanceEntry instances will always be of subtypes PerformanceNavigationTiming
  // See https://developer.mozilla.org/en-US/docs/Web/API/PerformanceNavigationTiming
  try {
    perfEntries = performance.getEntriesByType("navigation");
    // There should only be a single performance entry.
    for (let i = 0; i < perfEntries.length; i++) {
      p = perfEntries[i];
      /* TODO - revisit this after Event Publishing API
      Logger.debug('= Navigation entry[' + i + ']');
      // dom Properties
      Logger.debug('DOM content loaded = ' + (p.domContentLoadedEventEnd - p.domContentLoadedEventStart));
      Logger.debug('DOM complete = ' + p.domComplete);
      Logger.debug('DOM interactive = ' + p.interactive);
      // document load and unload time
      Logger.debug('document load = ' + (p.loadEventEnd - p.loadEventStart));
      Logger.debug('document unload = ' + (p.unloadEventEnd - p.unloadEventStart));
      // other properties
      Logger.debug('type = ' + p.type);
      Logger.debug('redirectCount = ' + p.redirectCount);
      */
    }
    // const js = performance.toJSON();
    // Logger.debug('perf json = ' + JSON.stringify(js));
  } catch (err) {
    Logger.warn(err);
  }

  return new PerformanceMetrics(p);
}
