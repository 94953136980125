import React from "react";
import { IntlProvider } from "react-intl";
import { getLocaleFromBrowser, getLocalizedMessages } from "./locale.js";

interface IntlerProps {
  isI18n: boolean;
  children: React.ReactNode;
}
export const I18nProvider = ({ isI18n, children }: IntlerProps) => {
  let locale = "en-US";
  if (isI18n) {
    locale = getLocaleFromBrowser();
  }
  const messages = getLocalizedMessages(locale);

  return (
    <IntlProvider locale={ locale } messages={ messages }>
      {children}
    </IntlProvider>
  );
};
