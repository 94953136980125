import React from "react";
import classes from "./trustArc.module.scss";

export const TA_PREFERENCES_ID = "teconsent"; // configurable
export const TA_BANNER_ID = "consent_blackbar"; // Do not change this id. It is not configurable

export const TrustArcCookiePreferences = () => {
  return <span id={ TA_PREFERENCES_ID }></span>;
};

// Shows consent dialog.
// If consent is required and is not yet captured, it will hide the children until the user has input consent
export const TrustArcConsentDialog = () => {
  return (
    // TrustArc handles the visibility of the banner elements based on the user's consent actions
    <div id={ TA_BANNER_ID } className={ classes.parkingTrustArcBanner }></div>
  );
};
