import React, { createContext, useContext, useReducer } from "react";
import { mergeTerms } from "./configMergeUtils";
import { getExperimentBannerLink, getChannelID } from "./experiment-helpers";
import { LanderConfig } from "../types/LanderConfig";

export enum LanderConfigAction {
  UPDATE_BANNER_AND_CHANNEL_BY_TREATMENT = "UPDATE_BANNER_AND_CHANNEL_BY_TREATMENT",
  UPDATE_CHANNEL_BY_TREATMENT = "UPDATE_CHANNEL_BY_TREATMENT",
}

interface LanderConfigState {
  landerConfig: LanderConfig;
  queryConfig: object;
  mergedConfig: object;
}

interface LanderConfigContext extends LanderConfigState {
  landerConfigDispatch: React.Dispatch<{
    type: LanderConfigAction;
    payload?: any;
  }>;
}

export const ConfigContext = createContext<LanderConfigContext>(null);

export const useLanderConfig = () => {
  const ctx = useContext(ConfigContext);

  if (ctx == null) {
    throw new Error(
      "useLanderConfig must be called within LanderConfigProvider"
    );
  }

  return ctx;
};

export const initConfigState = ({
  landerConfig,
  queryConfig,
}): LanderConfigState => {
  const mergedConfig = {
    keywords: mergeTerms(landerConfig, queryConfig),
  };

  return {
    landerConfig,
    queryConfig,
    mergedConfig,
  };
};

export function configStateReducer(
  state: LanderConfigState,
  { type, payload }: { type: LanderConfigAction; payload?: any }
): LanderConfigState {
  const newState = JSON.parse(JSON.stringify(state));
  const inTreatmentCohort = payload as boolean;

  switch (type) {
    case LanderConfigAction.UPDATE_BANNER_AND_CHANNEL_BY_TREATMENT: {
      newState.landerConfig.lander.banner.link = getExperimentBannerLink(
        inTreatmentCohort,
        state.landerConfig
      );
      newState.landerConfig.adSense.channel = getChannelID(
        inTreatmentCohort,
        state.landerConfig
      );
      return newState;
    }
    case LanderConfigAction.UPDATE_CHANNEL_BY_TREATMENT: {
      newState.landerConfig.adSense.channel = getChannelID(
        inTreatmentCohort,
        state.landerConfig
      );
      return newState;
    }
    default:
      return state;
  }
}

interface Props {
  landerConfig: object;
  queryConfig: object;
  children: React.ReactNode;
}

export const LanderConfigProvider = ({
  landerConfig,
  queryConfig,
  children,
}: Props) => {
  const [configState, configStateDispatch] = useReducer(
    configStateReducer,
    {
      landerConfig,
      queryConfig,
    },
    initConfigState
  );

  return (
    <ConfigContext.Provider
      value={{
        ...configState,
        landerConfigDispatch: configStateDispatch,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
