import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import classes from "./footer.module.scss";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TrustArcCookiePreferences } from "../../consentManager/TrustArcComponents";
import classNames from "classnames";

export const FooterParkWeb = ({
  copyrightTextId,
  parkwebDisclaimerText = null,
  darkMode = false,
}) => {
  return (
    <div
      className={ classNames(classes.parkwebFooter, {
        [classes.darkMode]: darkMode,
      }) }
    >
      <div className={ classes.spaceBetween }>
        <span className={ classes.centerText }>
          <FormattedMessage
            id={ copyrightTextId }
            values={{ currentYear: new Date().getFullYear() }}
          />
        </span>
        <PrivacyPolicy />
        <TrustArcCookiePreferences />
      </div>
      {parkwebDisclaimerText && (
        <p className={ classes.parkwebDisclaimerText }>
          <FormattedMessage id={ parkwebDisclaimerText } />
        </p>
      )}
    </div>
  );
};

FooterParkWeb.propTypes = {
  copyrightTextId: PropTypes.string,
  parkwebDisclaimerText: PropTypes.string,
  darkMode: PropTypes.bool,
};
