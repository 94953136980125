import React from "react";
import { FormattedMessage } from "react-intl";
import { useLanderConfig } from "../commons/ConfigContext";
import { EventType, QueryParam, System } from "../commons/Constants";
import { postEvent } from "../commons/EventHelper";
import { createBaseLanderEvent } from "../commons/LanderEvent";
import { Settings } from "../config";
import { LanderConfig } from "../types/LanderConfig";
import { VerizonContent } from "../verizon/VerizonContent";
import { AdSense } from "./utils/adSenseUtils";
import { useAdsenseContext } from "./AdsenseContext";
import { useExperiment } from "../experimentation/useExperiment";
import { experimentIds } from "../commons/experiment-helpers";

function AdSenseBlock({ landerConfig, page, queryConfig }) {
  let adsenseContentDivId = "ads";
  if (page === AdSense.page.RELATED_LINKS) {
    adsenseContentDivId = "relatedLinks";
  }

  let search;
  if (
    !landerConfig.lander.template.includes("PARK_WEB") &&
    queryConfig[QueryParam.SEARCH_BOX]
  ) {
    search = (
      <div id="searchbox">
        <FormattedMessage id="contentBlockedText" />
      </div>
    );
  }

  return (
    <>
      <div data-testid={ adsenseContentDivId } id={ adsenseContentDivId }>
        <FormattedMessage id="contentBlockedText" />
      </div>
      {search}
    </>
  );
}

export function AdContent({ page }: { page: string }) {
  const { landerConfig, queryConfig } = useLanderConfig();
  const { hasAdsense } = useAdsenseContext();
  const { inTreatmentCohort: yahooExperiment } = useExperiment(
    experimentIds.yahooFeedExperimentPart2Id
  );

  if (landerConfig.isForcedVerizon || yahooExperiment) {
    return <VerizonContent />;
  }

  if (hasAdsense === false) {
    if (landerConfig.system === System.PARKWEB) {
      redirectToSerp(landerConfig.lander.banner?.link, landerConfig);
    }
    return <VerizonContent />;
  }

  return (
    <AdSenseBlock
      landerConfig={ landerConfig }
      queryConfig={ queryConfig }
      page={ page }
    />
  );
}

function redirectToSerp(url: string, landerConfig: LanderConfig) {
  const serpLink = url + "&redirect=true";
  postEvent(Settings.EVENT_PUBLISH_API, {
    ...createBaseLanderEvent(EventType.SERP_REDIRECT, landerConfig),
    serpLink,
  });
  window.location.assign(serpLink);
}
