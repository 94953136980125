import { useState, useEffect } from "react";

export const defaultFontStyle = { fontSize: "36px" };

// Dynamically calculated font-size to prevent line-wrap when displaying domain names.
// Note: Using CSS 'white-space: nowrap;' has unwanted effects.

export default function useNoWrapFontStyle(
  text,
  containerRef,
  textLengthLimit
) {
  const [fontSize, setFontSize] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      setFontSize((containerRef.current.offsetWidth / text.length) * 9);
    };
    if (containerRef?.current) updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => window.removeEventListener("resize", updateWidth);
  }, [containerRef, text.length]);

  return text.length > textLengthLimit && fontSize > 0
    ? { fontSize: `min(${fontSize}%, 36px)` }
    : defaultFontStyle;
}
