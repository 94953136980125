/**
 * Selector name and array of lines
 * Example: new CssRule('#tdfsBanner:hover', ['padding: .7em .5em;'])
 */
export default class CssRule {
  constructor(name, lines) {
    this.name = name;
    this.lines = lines;
  }
}

/**
 * Create CSS rule-set with selector and a declaration block
 * @param name css selector
 * @param lines declarations
 * @returns {string}
 */
export function cssRule(name, lines) {
  return `${name} { ${lines.map(semiColonCatcher).join("\n\t")} }`;
}

/**
 * Create multiple CSS rule-sets
 * @param rules: array of CssRule
 * @returns {string}
 */
export function cssRules(rules) {
  return rules.map(
    (rule) =>
      `${rule.name} { ${rule.lines.map(semiColonCatcher).join("\n\t")} }\n`
  );
}

/**
 * Appends semi colon to string if none is found
 */
function semiColonCatcher(str) {
  if (str[str.length - 1] !== ";") return str + ";";
  return str;
}
