import React from "react";
import dompurify from "dompurify";
import PropTypes from "prop-types";
import ChevronRightIcon from "@ux/icon/chevron-right";
import { sendVerizonEvent } from "./verizonFeed";
import { useLanderConfig } from "../commons/ConfigContext";
import "@ux/icon/chevron-right/index.css";

export const YahooAds = ({ xmlResult }) => {
  const { landerConfig } = useLanderConfig();
  const resultSet =
    xmlResult?.getElementById("adListings")?.getElementsByTagName("Listing") ??
    [];
  const yahooFeed = Array.from(resultSet);
  const handleAdClick = () => {
    sendVerizonEvent("success", true, landerConfig, true);
  };
  if (!yahooFeed) return null;
  return (
    <div className="yahooAds">
      <ul>
        {yahooFeed.map((item: any, itemIndex) => {
          const siteHost = `http://${item.getAttribute("siteHost")}`;
          const href =
            item.getElementsByTagName("ClickUrl")[0]?.childNodes[0]
              ?.textContent ?? "#";

          return (
            <li key={ `yahooFeedAdItem${itemIndex}` } className="yahooFeedAdItem">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={ href }
                className="adLink"
                onClick={ handleAdClick }
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(item.getAttribute("title")),
                }}
              />
              <div className="adSiteHost">
                <span>Ad</span>
                <a
                  onClick={ handleAdClick }
                  target="_blank"
                  rel="noopener noreferrer"
                  href={ siteHost }
                >
                  {siteHost}
                </a>
              </div>
              <p
                className="adDescription"
                dangerouslySetInnerHTML={{
                  __html: dompurify.sanitize(item.getAttribute("description")),
                }}
              />
              <div className="adVisitButton">
                <button onClick={ handleAdClick }>
                  <ChevronRightIcon
                    style={{ color: "black" }}
                    height="20"
                    width="20"
                  />
                  <a href={ href }>Visit Website</a>
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

YahooAds.propTypes = {
  xmlResult: PropTypes.object,
};
