import React from "react";
import { Settings } from "../../config";
import { FormattedMessage } from "react-intl";
import { useLanderConfig } from "../../commons/ConfigContext";

export const PrivacyPolicy = () => {
  const { landerConfig } = useLanderConfig();
  const privacyUrl =
    (landerConfig.turnKeyReseller || {}).privacyUrl ||
    Settings.PRIVACY_POLICY_URL;

  function getModalPrivacy() {
    window.open(
      privacyUrl,
      "privacy",
      "width=520,height=400,left=250,top=250,menubar=no,status=yes,toolbar=no,scrollbars=yes,resizable=yes"
    );
  }
  return (
    <span id="privacy">
      <a href="#!" rel="nofollow" onClick={ getModalPrivacy }>
        <FormattedMessage id="privacyPolicyText" />
      </a>
    </span>
  );
};
