import { abpParams } from "../adblock/constants";
import Logger from "./Logger";
/**
 * Convert event object to JSON string
 * Ignores null, undefined or empty values in JSON
 * @param eventObj
 * @returns {*}
 */
export function eventToJsonString(eventObj) {
  let eventJson;
  try {
    // ignore null, undefined or empty values
    eventJson = JSON.stringify(eventObj, (key, value) => {
      if (value !== null && typeof value !== "undefined" && value !== "")
        return value;
    });
  } catch (e) {
    Logger.error(e);
  }
  Logger.debug("lander event: " + eventJson);
  return eventJson;
}

/**
 * Publish Park Page Event: CLICK, VISIT, etc.
 * Ignores `null` or `undefined` values.
 * NOTE: This function currently does not use async so we can optimize build size for forwarder.
 * @param apiEndpoint API endpoint for POST request
 * @param eventObj Lander event object
 */
export function postEvent(apiEndpoint, eventObj) {
  const eventJson = eventToJsonString(eventObj);

  apiEndpoint += "?" + abpParams;

  // Perform POST requst and send event as JSON
  return fetch(apiEndpoint, {
    method: "POST",
    credentials: "omit", // Never send or receive cookies.
    body: eventJson,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        Logger.error(
          "error publishing event - HTTP Status: " + response.status
        );
      }
    })
    .catch((error) => {
      Logger.error("error publishing event - " + error.toString());
    });
}
