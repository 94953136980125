import { useState, useEffect } from "react";
import { generateUUID } from "./HelperFunctions";

export const useFetch = (url: string) => {
  const [body, setBody] = useState<any>(null);
  const [err, setErr] = useState(null);
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "X-Request-Id": generateUUID(),
          },
          credentials: "include",
        });
        if (response.status !== 200) {
          throw Error(`Unexpected status :${response.status}`);
        }
        setBody(await response.json());
      } catch (error) {
        setErr(error);
      }
    })();
  }, [url]);

  return {
    body: body,
    err: err,
  };
};
