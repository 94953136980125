import { AdSense } from "../../adsense/utils/adSenseUtils";

export const ValidTemplates = [
  AdSense.template.SIMPLE_2C,
  AdSense.template.SIMPLE_2C_LIGHT,
  AdSense.template.SIMPLE_2C_EXPERIMENT,
  AdSense.template.LIGHT_BLUE,
  AdSense.template.VERTICAL_LINES,
  AdSense.template.ARROW_1,
  AdSense.template.ARROW_2,
  AdSense.template.ARROW_3,
  AdSense.template.PARK_WEB,
  AdSense.template.PARK_WEB_HEG,
];
