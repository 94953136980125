import { parseBoolean } from "../commons/BooleanHelper";

export const Settings = {
  DOMAIN_CONFIG_API: process.env.REACT_APP_DOMAIN_CONFIG_API,
  PARKWEB_LANDER_API: process.env.REACT_APP_PARKWEB_LANDER_API,
  EVENT_PUBLISH_API: process.env.REACT_APP_EVENT_PUBLISH_API,
  GODADDY_HOST: process.env.REACT_APP_GODADDY_HOST,
  GOOGLE_ADSENSE_ADTEST: process.env.REACT_APP_ADSENSE_ADTEST,
  DEBUG_MODE: parseBoolean(process.env.REACT_APP_DEBUG_MODE),
  VERIZON_FEED_PARTNER: {
    TEST: process.env.REACT_APP_VERIZON_FEED_PARTNER_TEST,
    PW: process.env.REACT_APP_VERIZON_FEED_PARTNER_PW,
    SN: process.env.REACT_APP_VERIZON_FEED_PARTNER_SN,
    CP: process.env.REACT_APP_VERIZON_FEED_PARTNER_CP,
  },
  YAHOO_FEED_PARTNER: {
    TEST: process.env.REACT_APP_VERIZON_FEED_PARTNER_TEST,
    PW: process.env.REACT_APP_YAHOO_FEED_PARTNER_PW,
    SN: process.env.REACT_APP_YAHOO_FEED_PARTNER_SN,
    CP: process.env.REACT_APP_YAHOO_FEED_PARTNER_CP,
  },
  VERIZON_FEED_PROXY: process.env.REACT_APP_VERIZON_FEED_PROXY,
  VERIZON_FEED_PATH:
    process.env.REACT_APP_VERIZON_FEED_PROXY +
    "/d/search/p/godaddy/xml/domain/multiset/v4/",
  PRIVACY_POLICY_URL:
    "https://www.godaddy.com/agreements/showdoc?pageid=PRIVACY",
  ForwarderLanderToUrl: {
    DAN: process.env.REACT_APP_FORWARDER_LANDER_URL_DAN,
    TDFS_GD: process.env.REACT_APP_FORWARDER_LANDER_URL_TDFS_GD,
    TDFS_AFTERNIC: process.env.REACT_APP_FORWARDER_LANDER_URL_TDFS_AFTERNIC,
  },
  FORWARDER_LANDER_API: process.env.REACT_APP_FORWARDER_LANDER_API,
  NORMAL_DOMAIN_LENGTH_LIMIT: 20,
  CONSENT_MANAGER_JS: process.env.REACT_APP_CONSENT_MANAGER_JS,
  CONSENT_MANAGER_CSS: process.env.REACT_APP_CONSENT_MANAGER_CSS,
  PARKING_ENV: process.env.REACT_APP_PARKING_ENV,
  TRUSTARC_SCRIPT_URI: process.env.REACT_APP_TRUSTARC_SCRIPT_URI,
  TRUSTARC_DOMAIN_ID: process.env.REACT_APP_TRUSTARC_DOMAIN_ID,
  TRUSTPILOT_URL: process.env.REACT_APP_TRUSTPILOT_URL,
  TRUSTPILOT_BUSINESS_UNIT_ID: process.env.REACT_APP_TRUSTPILOT_ID,
  AUCTION_DETAILS_PAGE: process.env.REACT_APP_AUCTION_DETAILS_URL,
  ACTION_SERVICE: process.env.REACT_APP_ACTION_SERVICE,
};
export const ENVS = {
  PROD: "prod",
  STAGE: "stage",
  DEV_PRIVATE: "dev-private",
  TEST: "test",
};
