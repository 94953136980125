import { QueryParam } from "./Constants";

export const mergeTerms = (landerConfig, queryConfig) => {
  if (typeof queryConfig[QueryParam.TERM] !== "undefined") {
    if (Array.isArray(queryConfig[QueryParam.TERM]))
      return queryConfig[QueryParam.TERM];
    return [queryConfig[QueryParam.TERM]];
  } else if (typeof landerConfig.adSense.keywords !== "undefined") {
    return landerConfig.adSense.keywords;
  }
  return [];
};
