import qs from "qs";
import { getLanderTemplate } from "../adsense/utils/adSenseUtils";
import { Settings } from "../config";
import { LanderConfig } from "../types/LanderConfig";
import { parseBoolean } from "./BooleanHelper";
import { LanderCookie, QueryParam, System, TDFSCookie } from "./Constants";
import {
  getCookie,
  isEmptyOrUndefined,
  getDomainName,
} from "./HelperFunctions";
import { getExperiment } from "./experiment-helpers";
import { abpParams } from "../adblock/constants";

// URL query fields of type boolean (0,1,true,false)
const BooleanQueryFields = [
  QueryParam.SHOW_DOMAIN,
  QueryParam.TDFS,
  QueryParam.BACK_FILL,
  QueryParam.SEARCH_BOX,
  QueryParam.SEARCH_BUTTON,
  QueryParam.DEBUG_MODE,
  QueryParam.VERIZON_FEED,
];

// Parses lander URL query parameters. Parse boolean values (0,1,true,false)
export function parseLanderQueryParam(queryString) {
  const queryParam = qs.parse(queryString, {
    ignoreQueryPrefix: true,
    decoder: function (str, defaultDecoder, charset, type) {
      if (type === "key") {
        return str.toLowerCase();
      } else if (type === "value") {
        return defaultDecoder(str, null, charset);
      }
    },
  });

  if (isEmptyOrUndefined(queryParam)) {
    return {};
  }

  // Parse all boolean query field
  BooleanQueryFields.forEach(function (field) {
    if (field in queryParam) {
      queryParam[field] = parseBoolean(queryParam[field]);
    }
  });

  return queryParam;
}

/**
 * Create a new lander object using fields from both Lander API and Query parameters.
 * Some query parameters overrides the lander API result.
 * @param landerConfig
 * @param queryConfig
 * @return object
 */
// eslint-disable-next-line complexity
export function createLanderConfig(landerConfig, queryConfig): LanderConfig {
  // response from PW API is nested in untargeted field
  if (landerConfig.untargeted) {
    landerConfig = landerConfig.untargeted;
  }

  const lander: LanderConfig = {
    account: landerConfig.account,
    customerId: landerConfig.customerId,
    portfolioId: landerConfig.portfolioId,
    dataSource: landerConfig.dataSource,
    displayType: landerConfig.displayType,
    rotatorId: landerConfig.rotatorId,
    system: landerConfig.system,
    experiment: {},
  };

  // Domain
  const domainObj = landerConfig.domain || {};
  lander.domain = {
    rootDomain: domainObj.rootDomain,
    rootDomainDisplayText: domainObj.rootDomainDisplayText,
    rootDomainFromBrowser: domainObj.rootDomainFromBrowser,
    expiresAt: domainObj.expiresAt,
    expired: domainObj.expired,
    encryptedDomain: domainObj.encryptedDomain,
    status: {
      internal: (domainObj.status || {}).internal,
    },
    isAdult: domainObj.isAdult,
    hasAuction: domainObj.hasAuction,
  };

  // AdSense
  if (landerConfig.adSense) {
    lander.adSense = {
      channel: landerConfig.adSense.channel,
      drid: landerConfig.adSense.drid,
      pubId: landerConfig.adSense.pubId,
      keywords: queryConfig[QueryParam.TERM] || landerConfig.adSense.keywords,
    };
  }

  // Experiment
  lander.experiment = getExperiment(landerConfig);
  lander.experiment.tdfsExp = getCookie(TDFSCookie.EXP);

  // Lander
  if (landerConfig.lander) {
    lander.lander = {
      template: getLanderTemplate(landerConfig, queryConfig),
      domainDisplayName: landerConfig.lander.domainDisplayName,
      headerText: landerConfig.lander.headerText,
      headerHtml: landerConfig.lander.headerHtml,
      footerText: landerConfig.lander.footerText,
      footerHtml: landerConfig.lander.footerHtml,
      eventCallbackUrl: landerConfig.lander.eventCallbackUrl,
      showDomain: landerConfig.lander.showDomain,
      i18n: landerConfig.lander.i18n,
      searchCount: landerConfig.lander.searchCount,
    };
  }

  // Lander: Banner
  if ((landerConfig.lander || {}).banner) {
    lander.lander.banner = {
      show:
        typeof queryConfig[QueryParam.TDFS] === "boolean"
          ? queryConfig[QueryParam.TDFS]
          : landerConfig.lander.banner.show,
      type: landerConfig.lander.banner.type,
      link: landerConfig.lander.banner.link,
      text: landerConfig.lander.banner.text,
    };
  }

  // Turnkey reseller
  if (landerConfig.turnKeyReseller) {
    lander.turnKeyReseller = {
      displayName: landerConfig.turnKeyReseller.displayName,
      programId: landerConfig.turnKeyReseller.programId,
      privateLabelId: landerConfig.turnKeyReseller.privateLabelId,
      privacyUrl: landerConfig.turnKeyReseller.privacyUrl,
      redirectUrl: landerConfig.turnKeyReseller.redirectUrl,
    };
  }

  lander.isForcedVerizon = queryConfig[QueryParam.VERIZON_FEED];

  return lander;
}

// Returns expiry partner config from cookie (cookie format: expiryPartner.portfolioType.portfolioId)
export function getExpiryPartnerConfig() {
  const expiryConfig = getCookie(LanderCookie.EXPIRY_PARTNER);
  const [expiryPartner, portfolioType, portfolioId] = expiryConfig.split(".");
  return {
    expiryPartner: expiryPartner,
    portfolioType: portfolioType,
    portfolioId: portfolioId,
  };
}

export const getApiUrl = (queryConfig: { [key: string]: unknown }) => {
  const domainName = getDomainName(queryConfig);
  let landerSystem = getCookie(LanderCookie.SYSTEM);
  if (landerSystem === "") landerSystem = window.LANDER_SYSTEM;

  let landerApi;
  if (landerSystem === System.PARKWEB) {
    const trafficTarget = getCookie(LanderCookie.TRAFFIC_TARGET);
    landerApi = `${Settings.PARKWEB_LANDER_API}/v1/parking/landers/${domainName}?trafficTarget=${trafficTarget}&${abpParams}`;
  } else {
    const expiryPortfolioId = getExpiryPartnerConfig().portfolioId || "";
    landerApi = `${Settings.DOMAIN_CONFIG_API}/v1/domains/domain?domain=${domainName}&portfolioId=${expiryPortfolioId}&${abpParams}`;
  }
  return landerApi;
};
