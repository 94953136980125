import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import { FetchStatus, LanderCookie } from "../commons/Constants";
import {
  verizonXmlFetch,
  getVerizonRSUri,
  sendVerizonEvent,
} from "./verizonFeed";
import { useLanderConfig } from "../commons/ConfigContext";
import { getCookie } from "../commons/HelperFunctions";
import { useExperiment } from "../experimentation/useExperiment";
import { experimentIds } from "../commons/experiment-helpers";
import { VerizonAds } from "./VerizonAds";
import { VerizonRS } from "./VerizonRS";
import { YahooAds } from "./YahooAds";
import { YahooRS } from "./YahooRS";
import { SearchBox } from "./SearchBox";
import classes from "./YahooAds.module.scss";
import PropTypes from "prop-types";

const YahooAdContent = ({ isAdClicked, xmlResult }) => {
  return (
    <>
      {isAdClicked ? (
        <YahooAds xmlResult={ xmlResult } />
      ) : (
        <YahooRS xmlResult={ xmlResult } />
      )}
      <SearchBox />
    </>
  );
};
YahooAdContent.propTypes = {
  isAdClicked: PropTypes.bool,
  xmlResult: PropTypes.object,
};

const VerizonAdContent = ({ isAdClicked, xmlResult }) => {
  return (
    <>
      {isAdClicked ? (
        <VerizonAds xmlResult={ xmlResult } />
      ) : (
        <VerizonRS xmlResult={ xmlResult } />
      )}
      <SearchBox />
    </>
  );
};
VerizonAdContent.propTypes = YahooAdContent.propTypes;

export const VerizonContent = () => {
  const { landerConfig, mergedConfig } = useLanderConfig();
  const { inTreatmentCohort: yahooExperiment } = useExperiment(
    experimentIds.optimizedYahooFeedExperimentId
  );

  const [fetchState, setFetchState] = useState(FetchStatus.PENDING);
  const [xmlResult, setXmlResult] = useState(null);

  const isAdClicked = new URLSearchParams(window.location.search).has("qs");

  useEffect(() => {
    // some bots don't have cookies, which results in their ip cookie having an unexpected value
    // in that case, getCookie will return an empty string
    if (!getCookie(LanderCookie.CLIENT_IP)) return;
    let ignore = false;

    let verizonUrl;
    // forward query params from browser url
    if (isAdClicked) verizonUrl = window.location.search;
    else
      verizonUrl = getVerizonRSUri(
        landerConfig.domain.rootDomain,
        mergedConfig.keywords,
        landerConfig.system,
        yahooExperiment
      );

    verizonXmlFetch(verizonUrl).then((v) => {
      if (ignore) return;
      setFetchState(v.fetchStatus);
      setXmlResult(v.xmlResult);
      if (!isAdClicked) {
        // logging ad clicks is now done right on the ad link
        sendVerizonEvent(v.message, false, landerConfig, yahooExperiment);
      }
    });

    return () => {
      ignore = true;
    };
  }, [
    landerConfig,
    landerConfig.domain.rootDomain,
    mergedConfig.keywords,
    isAdClicked,
    yahooExperiment,
  ]);

  let verizonContent;
  if (fetchState === FetchStatus.PENDING) {
    verizonContent = null;
  } else if (fetchState === FetchStatus.SUCCESS) {
    verizonContent = yahooExperiment ? (
      <YahooAdContent isAdClicked={ isAdClicked } xmlResult={ xmlResult } />
    ) : (
      <VerizonAdContent isAdClicked={ isAdClicked } xmlResult={ xmlResult } />
    );
  } else {
    verizonContent = <FormattedMessage id="contentBlockedText" />;
  }

  return (
    <div
      id={ classNames({
        "verizon-feed": !yahooExperiment,
      }) }
      className={ classNames({ [classes.yahooFeed]: yahooExperiment }) }
    >
      {verizonContent}
    </div>
  );
};
