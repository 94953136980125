import React from "react";
import reactHtmlParser from "html-react-parser";
import Logger from "../../commons/Logger";
import { CopyrightCashParking } from "./CopyrightCashParking";
import { PrivacyPolicy } from "./PrivacyPolicy";
import { TrustArcCookiePreferences } from "../../consentManager/TrustArcComponents";
import classes from "./footer.module.scss";
import { useLanderConfig } from "../../commons/ConfigContext";

export const FooterCashParking = () => {
  const { landerConfig } = useLanderConfig();

  let footerElement;
  if (landerConfig.lander.footerHtml) {
    let decodedHtml = "";
    try {
      decodedHtml = window.atob(landerConfig.lander.footerHtml);
    } catch (e) {
      Logger.error("footerHtml value is not properly encoded" + e);
    }
    footerElement = (
      <div className="footerLine footerColor">
        <div id="footerElement">{reactHtmlParser(decodedHtml)}</div>
      </div>
    );
  } else if (landerConfig.lander.footerText) {
    footerElement = (
      <div className="footerLine footerColor">
        <div id="footerElement">{landerConfig.lander.footerText}</div>
      </div>
    );
  }

  return (
    <div id="footer">
      {footerElement}
      <div className={ `footerLine footerColor ${classes.spaceBetween}` }>
        <CopyrightCashParking />
        <PrivacyPolicy />
        <TrustArcCookiePreferences />
      </div>
    </div>
  );
};
